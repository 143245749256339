import React from 'react';

const ImageSlider = ({ slides, currentIndex }) => {
  const sliderStyles = {
    height: '40em',
    position: "relative",
    zIndex: "100",
  };

  const slideStyles = {
    width: '75em',
    height: '40em',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${slides[currentIndex].url})`, 
    cursor: "pointer", // Hinzugefügt, um den Mauszeiger zu ändern
  };

  // Funktion, um beim Klicken auf das Bild zur entsprechenden URL weiterzuleiten
  const handleImageClick = () => {
    let url = "";
    // Überprüfen Sie den aktuellen Index und setzen Sie die URL entsprechend
    switch (currentIndex) {
      case 0:
        url = "http://www.der-gruenlandpfleger.de";
        break;
      case 1:
        url = "https://www.favycon.com";
        break;
      case 2:
        url = "https://www.malte-huelsebusch.de";
        break;
      case 3:
        url = "http://www.immobilien.favycon-solutions.com";
        break;
      case 4:
        url = "http://www.podologie.favycon-solutions.com/";
        break;
      default:
        url = "#"; // Fallback-URL
    }
    window.location.href = url;
  };

  return (
    <div style={sliderStyles}>
      {/* Verwenden Sie ein anklickbares <a> Element, um das Bild klickbar zu machen */}
      <a href={slides[currentIndex].link} onClick={handleImageClick}>
        <div style={slideStyles}></div>
      </a>
    </div>
  ); 
};

export default ImageSlider;
