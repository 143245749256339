import React, { useState, useRef, useEffect } from 'react';
import logo2 from './img/logoalone.png';
import './App.css';
import Navbar from './navbar.js';
import { Element } from 'react-scroll';
import ImageSlider from './ImageSlider';
import mockupGala from './img/mockupGala.png';
import mockupFavycon from './img/mockupfavycon.png';
import mockupFinanz from './img/mockupfinanzberatung.png';
import mockupImmo from './img/mockupImmo.png';
import mockupPodologie from './img/mockuppodologie.png';
import mitch from './img/mitch.png';
import janis from './img/profilePic.png';
import mitch2 from './img/mitch3.png';
import angular from './img/angular.svg';
import javascript from './img/js.svg';
import aws from './img/aws.svg';
import nodejs from './img/node.svg';
import php from './img/php.svg';
import reacticon from './img/react.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faDatabase } from '@fortawesome/free-solid-svg-icons';
import sql from './img/database-solid.svg';
import postgresql from './img/database-solid.svg';
import typescript from './img/TS2.png';
import hand from './img/hand.png';
import email from './email.js';
import insta from './insta.js';
import PhoneIcon from './phone.js';
import instagram from './img/instagram.svg';
import InstagramLink from './insta.js';
import EmailLink from './email.js';
import left from './img/chevron-left-solid.svg';
import right from './img/chevron-right-solid.svg';
import dominik from './img/dominik.png';
import TerminLink from './contact.js';
import Datenschutz from './datenschutz.js';



function App() {
  const [currentIndex, setCurrentIndex] = useState(0); // Definiere currentIndex und setCurrentIndex
  const [sectionVisibility, setSectionVisibility] = useState({});
  const [animateParagraphs, setAnimateParagraphs] = useState(false); // Definiere animateParagraphs und setAnimateParagraphs
  const imagesRef = useRef(null); // Ref für die Bilder

  const [isRightVisible, setIsRightVisible] = useState(true);

  const isSectionVisible = (sectionName) => {
    return sectionVisibility[sectionName] || false;
  };

  const slides = [
    { url: mockupGala, title: "Gartenlandschaftsbau" },
    { url: mockupFavycon, title: "Favycon" },
    { url: mockupFinanz, title: "Finanzberatung" },
    { url: mockupImmo, title: "Immobilien" },
    { url: mockupPodologie, title: "Podologie" },
  ];

  const containerStyles = {
    width: '75em',
    height: '40em',
    margin: '0 auto',
    position: 'relative', 
  };

  const buttonStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '2em',
    cursor: 'pointer',
  };

 // Funktion, um das Element um 1400px nach rechts zu verschieben
 function moveRight() {
  const handElement = document.querySelector('.hand');
  const currentMarginLeft = parseInt(handElement.style.marginLeft) || 0;
  const newMarginLeft = currentMarginLeft + 1400;
  handElement.style.marginLeft = `${newMarginLeft}px`;
  setIsRightVisible(false); // Rechter Button ausblenden
}

// Funktion, um die vorherige Aktion rückgängig zu machen
function moveLeft() {
  const handElement = document.querySelector('.hand');
  const currentMarginLeft = parseInt(handElement.style.marginLeft) || 0;
  const newMarginLeft = currentMarginLeft - 1400;
  handElement.style.marginLeft = `${newMarginLeft}px`;
  setIsRightVisible(true); // Rechten Button wieder anzeigen
}

useEffect(() => {
  // Wenn die Komponente gerendert wurde, setze das Ref auf die Bilder
  if (imagesRef.current) {
    const images = imagesRef.current.querySelectorAll('img');
    // Überprüfe, ob das Bild an der aktuellen Indexposition existiert
    if (images && images[currentIndex]) {
      images[currentIndex].classList.add('slide-in-right');
      // Entferne die Klasse nach der Animation
      setTimeout(() => {
        images[currentIndex].classList.remove('slide-in-right');
      }, 1500);
    }
  }
}, [currentIndex]); // Abhängigkeit von currentIndex, um die Effektfunktion bei Änderungen auszuführen



const email = 'info@favycon.com';
const subject = 'Allgemeines Interesse an Ihren Diensten';
const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

const handleImageClick = () => {
  window.location.href = mailtoLink; // Öffnet den E-Mail-Client beim Klicken auf das Bild
};



  const handleNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    // Setze currentIndex auf newIndex, bevor die Klassen hinzugefügt werden
    setCurrentIndex(newIndex);
    // Füge die Klasse hinzu, um das Bild von rechts einfliegen zu lassen
    const images = document.querySelectorAll('.slides img');
    console.log('images:', images);
    console.log('currentIndex:', currentIndex);
    console.log('images[currentIndex]:', images[currentIndex]);
    if (images && images[currentIndex]) {
      images[currentIndex].classList.remove('active');
      images[currentIndex].classList.add('slide-in-left');
    }
    if (images && images[newIndex]) {
      images[newIndex].classList.add('active', 'slide-in-right');
      // Entferne die Klasse nach der Animation
      setTimeout(() => {
        images[currentIndex].classList.remove('slide-in-left');
        images[newIndex].classList.remove('slide-in-right');
      }, 1500);
    }
  };
  
  
  const handlePrev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    // Setze currentIndex auf newIndex, bevor die Klassen hinzugefügt werden
    setCurrentIndex(newIndex);
    // Füge die Klasse hinzu, um das Bild von links einfliegen zu lassen
    const images = document.querySelectorAll('.slides img');
    if (images && images[currentIndex]) {
      images[currentIndex].classList.remove('active');
      images[currentIndex].classList.add('slide-in-right');
    }
    if (images && images[newIndex]) {
      images[newIndex].classList.add('active', 'slide-in-left');
      // Entferne die Klasse nach der Animation
      setTimeout(() => {
        images[currentIndex].classList.remove('slide-in-right');
        images[newIndex].classList.remove('slide-in-left');
      }, 1500);
    }
  };
  
  const [showSecondPage, setShowSecondPage] = useState(false);
  const [showContactIcons, setShowContactIcons] = useState(true);
  
  const toggleSecondPage = () => {
    setShowSecondPage((prevShowSecondPage) => !prevShowSecondPage);
    setShowContactIcons((prevShowContactIcons) => !prevShowContactIcons);
  };
  
  const zIndexValue = showSecondPage ? 30 : 1;
  const contactIconsZIndex = showSecondPage ? 1 : 30;
  
  
  const [showContent, setShowContent] = useState({});

  const toggleContent = (sectionName) => {
    setShowContent((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };



return (
  <div className="App">
    <header className="App-header">
      <Navbar />
    </header>
    <Element name="home-section" className="section">
      <div className={`home ${isSectionVisible('home-section') ? 'show' : ''}`}>
        <div className='homeContent'>
        <img src={logo2} className="App-logo2" alt="logo" />
        <p className='innovation-text'>
        FAVYCON Solutions
        </p>
        <span className='innovation-subtext'>
          Webentwicklung
        </span>
        <button  onClick={handleImageClick} className="btnContact">Beratungstermin vereinbaren</button>
        </div>
      </div>
    </Element>

    <Element name="about-section" className="section">
      <div className={`about ${isSectionVisible('about-section') ? 'show' : ''}`}>
        <div className="aboutus">
          <h1>Über uns</h1>
          <p>Bei Favycon Solutions konzentrieren wir uns darauf, innovative Webseiten- und App-Lösungen zu entwickeln, die die Anforderungen unserer Kunden erfüllen und ihre Visionen zum Leben erwecken. Unsere Expertise erstreckt sich über verschiedene Sprachen und Plattformen, und wir sind stolz darauf, maßgeschneiderte Lösungen anzubieten, die sowohl funktional als auch ästhetisch ansprechend sind.</p>
          <p>Unser Team von erfahrenen Entwicklern und Designer arbeitet eng mit unseren Kunden zusammen, um ihre Bedürfnisse zu verstehen und Lösungen zu entwickeln, die ihre Ziele unterstützen. Wir legen großen Wert darauf, innovative Ideen zu erforschen und diese mit bewährten Technologien und Best Practices zu kombinieren, um Produkte von höchster Qualität zu liefern.</p>
          <p>Egal ob es sich um eine dynamische Webseite, eine benutzerfreundliche App oder eine komplexe Single-Page-Applikation handelt, wir sind darauf spezialisiert, maßgeschneiderte Lösungen zu entwickeln, die den Anforderungen unserer Kunden gerecht werden. Dabei setzen wir auf eine agile Entwicklungsmethodik, um sicherzustellen, dass wir flexibel auf Änderungen reagieren können und gleichzeitig eine hohe Qualität gewährleisten.</p>
          <p>Unsere innovative Herangehensweise ermöglicht es uns, kreative Lösungen für komplexe Probleme zu finden und unseren Kunden einen Wettbewerbsvorteil zu verschaffen. Wir sind bestrebt, stets auf dem neuesten Stand der Technologie zu bleiben und neue Trends und Entwicklungen in der Branche zu nutzen, um unseren Kunden die bestmöglichen Lösungen zu bieten.</p>
          <p>Zusammenfassend können Sie sich darauf verlassen, dass Favycon Solutions Ihnen innovative Webseiten- und App-Lösungen bietet, die Ihre Erwartungen übertreffen und Ihr Unternehmen voranbringen. Wir sind Ihr zuverlässiger Partner für alle Ihre digitalen Projekte und freuen uns darauf, mit Ihnen zusammenzuarbeiten, um Ihre Visionen zu verwirklichen.</p>
        </div>
        <div className='picContainer'>
        {/* <img src={logo2} className="Applogo" alt="logo" /> */}
          <div className='mitchContainer'>
            <img src={mitch2} className="mitch" alt="logo" />
            <span>Michél Bente - CEO</span>
            <div className='skillContainer'>
              <div>
                <img className="skillIcons" src={angular} alt="angular" />
                <span>Angular</span>
              </div>
              <div>
                <img className="skillIcons" src={javascript} alt="javascript" />
                <span>JavaScript</span>
              </div>
              <div>
                <img className="skillIcons" src={php} alt="php" />
                <span>PHP</span>
              </div>
              <div>
                <img className="skillIcons" src={reacticon} alt="react" />
                <span>React</span>
              </div>
              <div>
                <img className="skillIcons" src={postgresql} alt="sql" />
                <span>MySQL/SQL</span>
              </div>
              <div>
                <img className="skillIcons" src={typescript} alt="typescript" />
                <span>TypeScript</span>
              </div>
            </div>
          </div>
          <div className='janisContainer'>
            <img src={janis} className="janis" alt="logo" />
            <span>Janis Hülsebusch - CEO</span>
            <div className='skillContainer2'>
              <div>
                <img className="skillIcons" src={angular} alt="angular" />
                <span>Angular</span>
              </div>
              <div>
                <img className="skillIcons" src={javascript} alt="javascript" />
                <span>JavaScript</span>
              </div>
              <div>
                <img className="skillIcons" src={aws} alt="aws" />
                <span>AWS</span>
              </div>
              <div>
                <img className="skillIcons" src={nodejs} alt="nodejs" />
                <span>Node.js</span>
              </div>
              <div>
                <img className="skillIcons" src={postgresql} alt="sql" />
                <span>PostgreSQL</span>
              </div>
              <div>
                <img className="skillIcons" src={typescript} alt="typescript" />
                <span>TypeScript</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
    <Element name="show-section" className="section">
        <div className={`show ${isSectionVisible('show-section') ? 'show' : ''}`}>
          <div className='mockupMaster'>
            <div style={containerStyles}>
              <button className="prev" style={{ ...buttonStyles, left: 0 }} onClick={handlePrev}>&#10094;</button>
              <button className="next" style={{ ...buttonStyles, right: 0 }} onClick={handleNext}>&#10095;</button>
              <div ref={imagesRef}>
                <ImageSlider slides={slides} currentIndex={currentIndex} />
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="contact-section" className="section">
      <div className={`contact ${isSectionVisible('contact-section') ? 'show' : ''}`}>
        <div className='outputContainer'>
          <div className='column'>
            {/* <span className='firstLetter'>K</span>
            <span className='firstSecond'>O</span>
            <span className='firstThird'>N</span>
            <span className='firstForth'>T</span>
            <span className='firstFifth'>A</span>
            <span className='firstSixt'>K</span>
            <span className='firstSeventh'>T</span> */}
            {/* <span className='firstEight'>G</span> */}
          </div>
          <div className='outputSkills'>
          {/* <div classList="outputLeft">
            <div className='uxui'onClick={() => toggleContent('uxui')}>
              <h3>UI / UX Design ►</h3>
              <p className={showContent['uxui'] ? 'show' : 'hide'}>
              "Tauchen Sie ein in ein visuelles Erlebnis, das nicht nur ästhetisch ansprechend ist, sondern auch nahtlos und intuitiv funktioniert - unser UI/UX-Design vereint Schönheit mit Benutzerfreundlichkeit, um Ihr Produkt auf ein neues Niveau der Exzellenz zu heben."
              </p>
            </div>
              <div className='angular' onClick={() => toggleContent('angular')}>
              <h3>Angular ►</h3>
              <p className={showContent['angular'] ? 'show' : 'hide'}>
                "Nutzen Sie die Kraft von Angular, um robuste und skalierbare Webanwendungen zu entwickeln, die durch eine klare Struktur, leistungsstarke Funktionen und eine reibungslose Benutzererfahrung überzeugen."
              </p>
            </div>
            <div className='php' onClick={() => toggleContent('php')}>
              <h3>PHP ►</h3>
              <p className={showContent['php'] ? 'show' : 'hide'}>
              "Erleben Sie die Vielseitigkeit von PHP, um dynamische und interaktive Webseiten sowie leistungsstarke Back-End-Systeme zu erstellen, die Ihre Anforderungen mit Leichtigkeit erfüllen."
              </p>
            </div>
            <div className='database'  onClick={() => toggleContent('database')}>
              <h3>Datenbankdesign ►</h3>
              <p className={showContent['database'] ? 'show' : 'hide'}>
              "Unser Datenbankdesign bietet eine solide Grundlage für die effiziente Organisation, Verwaltung und Analyse Ihrer Daten, damit Sie fundierte Entscheidungen treffen und Ihr Geschäftspotenzial optimal ausschöpfen können."
              </p>
            </div>          
          </div>
           <div classList="outputRight">
              <div className='react' onClick={() => toggleContent('react')}>
                <h3>React ►</h3>
                <p className={showContent['react'] ? 'show' : 'hide'}>
                "Entdecken Sie die Flexibilität und Leistungsfähigkeit von React, um interaktive und dynamische Benutzeroberflächen zu erstellen, die reaktionsschnell, skalierbar und benutzerfreundlich sind - und das alles mit einem modularen und komponentenbasierten Ansatz."
                </p>
             </div>
              <div className='javascript' onClick={() => toggleContent('javascript')}>
                <h3>JavaScript ►</h3>
                <p className={showContent['javascript'] ? 'show' : 'hide'}>
                "Mit JavaScript als Fundament können Sie innovative und interaktive Funktionen in Ihre Webanwendungen integrieren, die Benutzererfahrung verbessern und Ihre Projekte mit dynamischer Funktionalität zum Leben erwecken."
                </p>
              </div>
              <div className='sap' onClick={() => toggleContent('sap')}>
                <h3>SAP ►</h3>
                <p className={showContent['sap'] ? 'show' : 'hide'}>
                "Profitieren Sie von der Zuverlässigkeit und Effizienz von SAP-Lösungen, um Ihre Unternehmensprozesse zu optimieren, Daten zu verwalten und fundierte Entscheidungen zu treffen, die Ihr Unternehmen auf Erfolgskurs halten."
                </p>
              </div>
           </div> */}
            <div className="hand" style={{ marginLeft: '230px', marginTop: '620px', transition: 'margin-left 1s ease-out',  zIndex: zIndexValue }}>
              <img src={hand} alt="hand" />
              <div className='contactIcons'>
                <PhoneIcon phoneNumber="+491787806918" />
                <InstagramLink className="insta"></InstagramLink>
                <EmailLink/>
              </div>
              <div style={{ zIndex: zIndexValue }} className={showSecondPage ? 'secondPage' : 'secondPage hidden'}>
                <button alt="emailIcon"
                onClick={handleImageClick} 
                style={{ zIndex: contactIconsZIndex }} 
                className='contactUs'>Beratungstermin vereinbaren</button>
                <TerminLink/>
              </div>
              <button className='btnMobile' onClick={toggleSecondPage}></button>
              <button className='btnMobile2'onClick={toggleSecondPage}></button>
           </div>
        </div>
        <button className='slideBtn' onClick={moveRight} style={{ display: isRightVisible ? 'block' : 'none' }}>
           <img className='imageRight' src={right} alt ></img>
        </button>
        <button className='slideBtn2' onClick={moveLeft} style={{ display: isRightVisible ? 'none' : 'block' }}>
          <img className='imageRight' src={left} alt ></img>
        </button>
        </div>
      </div>
    </Element>
    <Element name="impressum-section" className="section">
    <div className={`impressum ${isSectionVisible('impressum-section') ? 'show' : ''}`}>
      <div id="impressum">
       
        <div className='impressumLeft'>
        <h2>Impressum</h2>
          <p>Angaben gemäß § 5 TMG</p>
          <p>Bente & Hülsebusch GbR</p>
          <p>Zur Feldmühle 9</p>
          <p>59821 Arnsberg</p>
          <p>Vertreten durch:</p>
          <p>Michél Bente und Janis Hülsebusch</p>
          <p>Kontakt:</p>
          <p>Telefon: 0178-7806918</p>
          <p>E-Mail: info@favycon.com</p>
          <p>Umsatzsteuer-ID: DE361356666</p>
          <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE355030954</p>
          <p>Verantwortliche nach § 18 Abs. 1 und 2 MStV:</p>
          <p>Bente & Hülsebusch GbR</p>
          <p>Zur Feldmühle 9</p>
          <p>59821 Arnsberg</p>
        </div>
        <div className='impressumRight'>
          <p>Hinweis zur EU-Streitschlichtung</p>
          <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/main/">https://ec.europa.eu/consumers/odr/main/</a> finden. Wir sind nicht bereit und auch nicht verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
          <p>Hinweis zu § 36 VSBG</p>
          <p>Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil und sind dazu auch nicht verpflichtet.</p>
          <h3>Haftungsausschluss:</h3>
          <h4>Haftung für Inhalte</h4>
          <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
          <h4>Haftung für Links</h4>
          <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
          <h4>Urheberrecht</h4>
          <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
          <h4>Datenschutz</h4>
          <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
        </div>  
      </div>
    </div>
    </Element>
      <footer>
        <div className='masterFooter'>
          <div className="footerColumn">
            <div className='imageContainerFooter'>   
              <img src={logo2} className="App-logo3" label="Favycon" alt="logo" />
            </div>
            <div className='spanContainerFooter'>   
                <span className='logoText'>Favyvon Solutions</span>
            </div>
          </div> 
          <div className='linkContainer'> 
            <Datenschutz/>
          </div>
          <div className='copyrightContainer'> 
            <span>Konzept, Design & Programmierung FAVYCON Solutions | Bente & Hülsebusch GbR</span>
          </div>
        </div>
      </footer>
  </div>
);
}

export default App;
