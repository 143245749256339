import React from 'react';
import InstagramIcon from './img/instagram.svg';

const InstagramLink = () => {
  const handleImageClick = () => {
    window.location.href = 'https://www.instagram.com/favycon';
  };

  return (
    <img
      className='instagram-icon'
      src={InstagramIcon}
      alt="Instagram Icon"
      onClick={handleImageClick}
    />
  );
};

export default InstagramLink;
