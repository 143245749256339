import React, { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './navbar.css';
import ComponentA from './domrouting'; 
import Domrouting from './domrouting';

const Navbar = () => {
  // Zustand für das Burger-Menü
  const [showMenu, setShowMenu] = useState(false);

  // Funktion zum Öffnen des Burger-Menüs
  const openMenu = () => {
    setShowMenu(true);
  };

  

  // Funktion zum Schließen des Burger-Menüs
  const closeMenu = () => {
    setShowMenu(false);
  };

  // Funktion zum Umschalten des Burger-Menüs
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Funktion zum Behalten des Menüs geöffnet, wenn ein Link ausgewählt wurde
  const keepMenuOpen = () => {
    // Keine Aktion ausführen, wenn das Menü bereits geöffnet ist
    if (showMenu) return;
    // Menü öffnen, wenn es geschlossen ist und ein Link ausgewählt wird
    openMenu();
  };

  // Funktion zum Scrollen nach oben beim Klicken auf den "Home"-Button
  const scrollToTop = () => {
    scroll.scrollToTop();
    closeMenu(); // Menü schließen, nachdem der "Home"-Button geklickt wurde
  };

  return (
    <div className="navbar">
      {/* Burger-Menü-Button */}
      <div className="navbar-menu-btn" onClick={toggleMenu}>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
      </div>

      {/* Navbar-Inhalte */}
      <div className={`navbar-menu-list ${showMenu ? 'show' : ''}`}>
        <Link
          to="home-section"
          spy={true}
          smooth={true}
          duration={500}
          className="btnshine"
          // onClick={scrollToTop} 
        >
          Home
        </Link>
        <Link
          to="about-section"
          spy={true}
          smooth={true}
          duration={500}
          className="btnshine"
          onClick={keepMenuOpen}
        >
          Über uns
        </Link>
        <Link
          to="show-section" 
          spy={true}
          smooth={true}
          duration={500}
          className="btnshine"
          onClick={keepMenuOpen}
        >
          Referenzen
        </Link>
        <Link
          to="contact-section"
          spy={true}
          smooth={true}
          duration={500}
          className="btnshine"
          onClick={keepMenuOpen}
        >
          Kontakt
        </Link>
        <Link
          to="impressum-section"
          spy={true}
          smooth={true}
          duration={500}
          className="btnshine"
          onClick={keepMenuOpen}
        >
          Impressum
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
