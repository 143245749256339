import React, { useState } from 'react';

const Popup = () => {
  // Zustand, um zu verfolgen, ob das Popup geöffnet ist oder nicht
  const [isOpen, setIsOpen] = useState(false);

  // Funktion, um das Popup mit Datenschutz-Inhalt zu öffnen
  const openPrivacyPolicyPopup = () => {
    // Inhalt der Datenschutzerklärung
    const privacyPolicyContent = `
      <div>
        <h2>Datenschutzerklärung</h2>
        <p><strong>Allgemeines</strong><br/>
        Die Einhaltung der Datenschutzgesetze ist für uns nicht nur gesetzliche Pflicht, sondern auch ein wichtiger Vertrauensfaktor. Mit den nachfolgenden Datenschutzbestimmungen möchten wir Sie deshalb transparent über Art, Umfang und Zweck der von Ihnen erhobenen und verarbeiteten personenbezogenen Daten innerhalb dieses Internetauftritts sowie Ihre Rechte informieren.</p>

        <p><strong>Verantwortlichkeit für die Datenverarbeitung</strong><br/>
        Favycon Solutions alias Bente & Hülsebusch GbR, Zur Feldmühle 9, 59821 Arnsberg, Telefon 0178/7806918 (im Folgenden: „Wir“) sind als Betreiber der Website favycon-solutions.com Verantwortlicher gem. Art. 4 Abs. Nr. 7 der EU-Datenschutz-Grundverordnung (DSGVO). Bei Fragen können Sie sich an info@favycon.com wenden.</p>

        <!-- Weitere Absätze hier einfügen -->

        <p><strong>Kontaktaufnahme</strong><br/>
        Unsere Webseite bietet Möglichkeiten an, direkt mit uns in Kontakt zu treten. Die von Ihnen an uns übermittelten Daten verarbeiten wir ausschließlich bis zur Erreichung des jeweiligen Zwecks Ihrer Kontaktaufnahme, es sei denn, dass gesetzliche Aufbewahrungsfristen entgegenstehen. Wenn Zweck Ihrer Kontaktaufnahme die Geltendmachung von Betroffenenrechten ist, gilt das im Abschnitt „Ihre Rechte als betroffene Person“ Gesagte. Wir verarbeiten sämtliche von Ihnen bei der Kontaktaufnahme angegebenen Daten, z.B. Ihren Namen und E-Mail-Adresse. Die Rechtsgrundlage für die Nutzung der von Ihnen durch die Kontaktaufnahme an uns übermittelten Daten im Rahmen von vertraglichen oder vorvertraglichen Beziehungen oder für die Beantwortung von (vor)vertraglichen Anfragen ist Art. 6 Abs. 1 S. 1 Buchstabe b) DSGVO (Datenverarbeitung zur Erfüllung eines Vertrags). Die Rechtsgrundlage für die Verarbeitung der von Ihnen durch die Kontaktaufnahme an uns übermittelten Daten in anderen Fällen als für vertragliche oder vorvertragliche Zwecke bzw. Anfragen ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Unser berechtigtes Interesse an der Verarbeitung ergibt sich aus unserem Interesse an der Beantwortung der Anfragen und der Pflege von Nutzerbeziehungen. Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.</p>
        
        <button onClick={closePopup}>Close</button>
      </div>
    `;

    // Öffnen des Popups
    const newWindow = window.open('favycon-solutions.com/Datenschutz', 'Datenschutz');
    if (newWindow) {
      newWindow.document.write(privacyPolicyContent);
      setIsOpen(true); // Setze isOpen auf true, um zu verfolgen, dass das Popup geöffnet ist
    }
  };

  // Funktion, um das Popup zu schließen und zur Website zurückzukehren
  const closePopup = () => {
    setIsOpen(false); // Setze isOpen auf false, um zu verfolgen, dass das Popup geschlossen ist
    window.opener.location.href = 'https://www.favycon-solutions.com'; // Leite den Benutzer zur Website zurück
    window.close(); // Schließe das Popup
  };

  return (
    <div>
      {/* Span für Datenschutz */}
      <span className="datenschutz" onClick={openPrivacyPolicyPopup}>Datenschutz</span>
    </div>
  );
};

export default Popup;
