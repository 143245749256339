import React from 'react';
import phoneIcon from './img/phone-solid.svg';

const PhoneIcon = ({ phoneNumber }) => {
  const handlePhoneCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <img
      className='phoneIcon'
      src={phoneIcon}
      alt="phoneIcon"
      onClick={handlePhoneCall}
    />
  );
};

export default PhoneIcon;
