import React from 'react';
import emailIcon from './img/envelope-regular.svg';

const TerminLink = () => {
  const email = 'info@favycon.com';
  const subject = 'Allgemeines Interesse an Ihren Diensten';
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

  const handleImageClick = () => {
    window.location.href = mailtoLink; // Öffnet den E-Mail-Client beim Klicken auf das Bild
  };

  return (
    <div>
      <img
        className='emailIcon2'
        src={emailIcon}
        alt="emailIcon"
        onClick={handleImageClick}
      />
      {/* <p>E-Mail: <a className='emaillink' href={mailtoLink}>{email}</a></p> */}
    </div>
  );
};

export default TerminLink;
